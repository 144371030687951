html,
body {
  padding: 0;
  margin: 0;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  scroll-behavior: smooth !important;
  position: relative;
}

th,
tr,
td,
div,
svg {
  color: #fff !important;
}

nav.navbar {
  background-color: #000 !important;
  font-weight: bold !important;
}

.dropdown-menu.dropdown-menu-right.shadow.show {
  transform: translate3d(-20px, -35px, 0px) !important;
}

@media screen and (max-width: 650px) {
  nav {
    width: 100vw !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }

  #roundToggler {
    position: relative !important;
    right: 0 !important;
    left: 0 !important;
    max-width: 50%;
  }

  .dropdown-menu.show {
    display: block !important;
    top: 165px !important;
    left: 0px !important;
    width: 100% !important;
  }

  .dropdown-menu.dropdown-menu-right.shadow.show {
    transform: translate3d(0px, -128px, 0px) !important;
  }

  .rootIcon {
    transform: translate(-50%, -25%) !important;
  }
}

.rootIcon {
  width: 110px;
  padding: 12px;
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-55%, -25%);
}

#roundToggler {
  z-index: 4001 !important;
  position: fixed;
  right: 80px;
  display: block;
  top: 13px !important;
  background-color: #fff !important;
  padding: 0 10px;
  border-radius: 5px;
  color: #000 !important;
  width: 150px;
}

.sticky-footer {
  background-color: var(--dark) !important;
  color: #fff !important;
}

a.nav-link {
  color: gold !important;
}

.leaderboardTable {
  background-color: var(--gray) !important;
  color: #fff !important;
}

th.MuiTableCell-root.MuiTableCell-head {
  background-color: var(--light-gray) !important;
}

span.MuiButtonBase-root.MuiCheckbox-root,
button.MuiButtonBase-root.MuiIconButton-root {
  background: #777;
  margin-right: 10px;
}

.card {
  background-color: var(--gray) !important;
  border-color: var(--dark) !important;
  color: #fff !important;
  border-left: 2px solid var(--warning) !important;
  box-shadow: 0px 2px 10px -7px #000 !important;
}

.card-header {
  border-bottom: unset !important;
  background-color: var(--light-gray) !important;
}

.menuOverlay {
  position: fixed;
  width: 100%;
  min-width: 100%;
  height: 100%;
  top: 0;
  padding: 25px 10px 0 10px;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 4000;
}

.menuGrid {
  top: 75px;
  position: relative;
  width: 90% !important;
  height: fit-content;
  margin: 0 auto;
  background-color: #1d1d1d;
  border: 2px solid #c6b1ff;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.menuGrid a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 125px;
}

.menuGrid img {
  width: 80px;
}

.globalNavBar {
  background-color: #1d1d1d;
  border-bottom: 3px solid #fff;
  box-shadow: 0px 3px 7px #000;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 4001 !important;
}

.globalNavIcons img:hover,
.globalNavIcons a:hover,
.globalNavIcons i:hover,
.footer-icons img:hover,
.fa-th.fs-4:hover {
  filter: gray !important;
  -webkit-filter: grayscale(100%) !important;
}

@media screen and (max-width: 950px) {
  .menuGrid a {
    padding: 10px 0;
  }

  .sidebar-icons img {
    width: 62px;
  }
}

@media screen and (max-width: 720px) {
  .globalNavIcons img {
    width: 60px;
  }

  #roundToggler {
    z-index: 3500 !important;
  }
}

.menuGrid p {
  margin: 0;
}

.fa-moon,
.nav-link .fa-cube {
  margin-top: 4px;
  margin-right: 8px;
  font-size: 0.9rem;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade-in {
  animation: fadeIn ease 3s;
  -webkit-animation: fadeIn ease 3s;
  -moz-animation: fadeIn ease 3s;
  -o-animation: fadeIn ease 3s;
  -ms-animation: fadeIn ease 3s;
}
.fade-in-fast {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.breathing-effect {
  -webkit-animation: breathing 7s ease-out infinite normal;
  animation: breathing 7s ease-out infinite normal;
  -webkit-font-smoothing: antialiased;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
  }

  25% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }

  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0) 85;
    transform: scale(0.85);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
  }

  25% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  50% {
    -webkit-transform: scale(0.95);
    -ms-transform: scale(0.95);
    transform: scale(0.95);
  }

  75% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.85);
    -ms-transform: scale(0.85);
    transform: scale(0.85);
  }
}
